import $ from "jquery";

// Footer Block Show Element
const imgDesktop = $("#img-desktop");

$(window).on(
  "scroll",
  elementVisibleClass.bind(null, imgDesktop, "footer-section__img-desktop_show")
);

function elementVisibleClass(elem, className) {
  if ($(window).scrollTop() > elem.offset().top - $(window).height() / 1.5) {
    elem.addClass(className);
  }
}

// Scroll To
const aboutLink = $("#about-link");
const aboutBlock = $("#about");

aboutLink.on("click", scrollTo.bind(null, aboutBlock));

function scrollTo(element) {
  $("html, body").animate(
    {
      scrollTop: element.offset().top,
    },
    500
  );
}

// Header Light Bg
const header = $("#header");

$(window).on("scroll", headerToggleLightBg);

function headerToggleLightBg() {
  if ($(window).scrollTop() > 30) {
    header.addClass("header_bg-light");
  } else {
    header.removeClass("header_bg-light");
  }

  if ($(window).scrollTop() > aboutBlock.offset().top - header.outerHeight()) {
    header.addClass("header_bg-dark");
  } else {
    header.removeClass("header_bg-dark");
  }
}
